body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#main {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}

#content {
  background-image: url("../src/Assets/images/Bg5.jpg") !important;
  background-repeat: no-repeat;
  background-color: gainsboro !important;
  background-size: cover;
  position: relative;
  background-position: bottom;
}

@media only screen and (max-width: 700px) {
  #content {
    background-image: url("../src/Assets/images/Bg5.jpg") !important;
    background-size: cover;
    background-position: bottom;
  }
}

.bg-common {
  background-color: RGBA(220, 220, 220, .8) !important;
}