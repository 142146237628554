#Header, #FooterContainer {
    background-color: #459c48;
    color: white !important;
}

#Header>div>ul>li>a {
    color: white !important;
}

#Header>div>ul>li {
    border: transparent .5px dotted !important;
}

#Header>div>ul>li:hover {
    border: orange .5px dotted !important;
}

.navbar-toggler {
    border-color: white !important;
    font-size: 15px !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

@media only screen and (max-width: 700px) {
    .ui.main.menu {
        display: none !important;
    }
    .main.menu .item, .main.menu .menu {
        display: none;
    }
    .secondary.menu {
        display: block!important;
    }
}

.jumbotron-custom {
    background-color: transparent !important;
}

#p-desc {
    font-size: 17px;
    margin: 0px 10%;
}

.div-title {
    font-size: 45px;
}

.tbl-display {
    max-width: 45% !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.div-event {
    max-width: 600px;
}

@media only screen and (max-width: 700px) {
    .div-title {
        font-size: 25px;
        font-weight: bold;
    }
    .tbl-display {
        max-width: 100% !important;
    }
}

#DivPreparedBy {
    font-weight: bolder;
    background-color: RGBA(220, 220, 220, .5);
    max-width: 300px !important;
}

.div-event-img {
    max-width: 420px;
    max-height: 420px;
}

.tbl {
    background-color: rgba(255, 255, 255, 0.8) !important;
}



@media only screen and (max-width: 480px) {
    #imgAreYouSaved {
        max-width: 320px;
        height: 400px;
    }
   
    #imgGospelVan {
        max-width: 320px;
        height: 400px;
    }

    #sr_001 {
        width: 300px;
        height: 400px;
    }
}
